/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          jQuery(function() {
              jQuery('.product-title').matchHeight();
              jQuery('.comparison-equalise').matchHeight();
              jQuery('.link-equalise').matchHeight();
              jQuery('.slideshow-equalise').matchHeight({byRow:false});
              jQuery('.footer-equalise').matchHeight({byRow:false});
              jQuery('.footer-equalise h4').matchHeight({byRow:false});
          });

          jQuery(window).smartresize(function(){
              jQuery('.product-title').matchHeight({remove:true}).matchHeight({});
              jQuery('.comparison-equalise').matchHeight({remove:true}).matchHeight();
              jQuery('.link-equalise').matchHeight({remove:true}).matchHeight();
              jQuery('.slideshow-equalise').matchHeight({remove:true}).matchHeight({byRow:false});
              jQuery('.footer-equalise').matchHeight({remove:true}).matchHeight({byRow:false});
              jQuery('.footer-equalise h4').matchHeight({remove:true}).matchHeight({byRow:false});
          });

          // The function actually applying the offset
          function offsetAnchor() {
              if (location.hash.length !== 0) {
                  window.scrollTo(window.scrollX, window.scrollY - 120);
              }
          }

          // Captures click events of all <a> elements with href starting with #
          jQuery(document).on('click', 'a[href^="#"]', function(event) {
              // Click events are captured before hashchanges. Timeout
              // causes offsetAnchor to be called after the page jump.
              window.setTimeout(function() {
                  offsetAnchor();
              }, 0);
          });

          // Set the offset when entering page with hash present in the url
          window.setTimeout(offsetAnchor, 0);



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Checkout page
    'secure_checkout': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

          gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
              if ( formId === 5 && fieldId === 3 ) {
                  optionsObj.minDate = '-100 Y';
                  optionsObj.maxDate = '-16 Y';
              }
              return optionsObj;
          } );
          gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
              if ( formId === 5 && fieldId === 21 ) {
                  optionsObj.minDate = '+1 D';
                  optionsObj.maxDate = '+6 M';
              }
              return optionsObj;
          } );


      }
    },
    // Considered page
    'have_you_considered': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


          jQuery(function() {
              jQuery('.additional label span').matchHeight();
              jQuery('.additional label span .add-title').matchHeight();
              jQuery('.additional label span .add-subtitle').matchHeight();
          });

          jQuery(window).smartresize(function(){
              jQuery('.additional label span').matchHeight();
              jQuery('.additional label span .add-title').matchHeight();
              jQuery('.additional label span .add-subtitle').matchHeight();
          });

          function calculateTotals() {
              var total = parseFloat(jQuery('#mainproduct').data('price'));
              var output = '';
              var elementTitle = '';
              var elementPrice = '';

              jQuery(':checked').each(function(index, element) {
                  elementTitle = $(element).data('title');
                  elementPrice = $(element).data('price');
                  if (elementTitle !== "") {
                      output = output + "<div class='row'><div class='col-xs-9 col-sm-6 col-md-4'>" + elementTitle + "</div><div class='col-xs-3 col-sm-3 col-md-2 text-right'>&pound;" + elementPrice + "</div></div>";
                      total = parseFloat(total) + parseFloat(elementPrice);
                  }
              });

              jQuery('#additional-products').html(output);
              jQuery('#total').html(total.toFixed(2));
          }

          calculateTotals();
          jQuery('.calculate').on('click', function() {
              calculateTotals();
          });


      }
    },
    // Checkout page
    'process_payment': {
          init: function() {
              // JavaScript to be fired on the home page
          },
          finalize: function() {
              // JavaScript to be fired on the home page, after the init JS

              jQuery('#worldpaybtn').click();

          }
      },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




(function($,sr){

    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;

        return function debounced () {
            var obj = this, args = arguments;
            function delayed () {
                if (!execAsap) {
                    func.apply(obj, args);
                }
                timeout = null;
            }

            if (timeout) {
                clearTimeout(timeout);
            } else if (execAsap) {
                func.apply(obj, args);
            }

            timeout = setTimeout(delayed, threshold || 100);
        };
    };
    // smartresize
    jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');

